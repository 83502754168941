import React from "react";
import { Column } from "../components/columns";
import { Italic, Small, SubTitle, Paragraph } from "../components/text";

// import './employee.sass'

export default ({ image, title }) => (
  <div>
    <img src={image} alt={title} />
    <Paragraph>{title}</Paragraph>
  </div>
);
